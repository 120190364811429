@import url('~react-leaflet-markercluster/dist/styles.min.css');
@import url('./css/leaflet.css');

.leaflet-popup {
  max-width: 90vw;
}

div.marker-cluster-small {
  background-color: rgba(108, 172, 228, 0.4);
}

div.marker-cluster-small div {
  background-color: rgba(108, 172, 228, 0.6);
  border: 2px solid #3a76ab;
}

div.marker-cluster-large,
div.marker-cluster-medium {
  background-color: rgba(242, 160, 15, 0.4);
}

div.marker-cluster-large div,
div.marker-cluster-medium div {
  background-color: rgba(242, 160, 15, 0.6);
  border: 2px solid #3a76ab;
}

div.marker-cluster-small span,
div.marker-cluster-medium span,
div.marker-cluster-large span {
  line-height: 28px;
  color: white;
  font-weight: 600;
}
